var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor-dialog"},[_c('v-dialog',{attrs:{"value":_vm.dialogModel(0),"max-width":"700px"},on:{"input":_vm.updateModel}},[_c('v-card',{},[_c('v-card-text',[(_vm.currentObj)?_c('v-container',[_vm._l((_vm.currentObj.writings),function(writing,writingIndex){return _c('v-row',{key:writing.id},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"icon":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.removeFromWriting(writingIndex)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-text-field',{staticClass:"writing-input",attrs:{"label":"Написание"},model:{value:(writing.value),callback:function ($$v) {_vm.$set(writing, "value", $$v)},expression:"writing.value"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('TagSelector',{attrs:{"initSelected":_vm.validateTag(writing),"type":'Kinf'},on:{"changeTags":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.changeWritingTags.apply(void 0, [ writingIndex ].concat( args ));
}}})],1)],1)}),_c('div',{staticClass:"dial-btn"},[_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.addWriting()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" написание")],1)],1),_c('v-divider',{staticStyle:{"padding":"20px"}}),_vm._l((_vm.currentObj.readings),function(readings,rIndex){return _c('v-row',{key:readings.id},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"icon":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.removeFromReading(rIndex)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-text-field',{staticClass:"writing-input",attrs:{"label":"Чтение"},model:{value:(readings.value),callback:function ($$v) {_vm.$set(readings, "value", $$v)},expression:"readings.value"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('TagSelector',{attrs:{"initSelected":_vm.validateTag(readings),"type":'Rinf'},on:{"changeTags":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.changeReadingTags.apply(void 0, [ rIndex ].concat( args ));
}}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"writing-input",attrs:{"label":"Питч"},model:{value:(readings.pitch),callback:function ($$v) {_vm.$set(readings, "pitch", $$v)},expression:"readings.pitch"}})],1)],1)}),_c('div',{staticClass:"dial-btn"},[_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.addReading()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" чтение")],1)],1)],2):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.removeCurrent}},[_vm._v(" Удалить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.updateModel}},[_vm._v(" ОК ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }